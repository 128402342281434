.App {
  text-align: center;
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
}

h1 {
  padding: 0;
  margin: 0;
}

h2 {
  padding: 0;
  margin: 0;
}

.FormTitle {
  font-size: 32px;
  font-weight: 700;
  padding: 8px 0px;
  text-align: center;
}

.FormLabel {
  font-size: 18px;
  font-weight: 700;
  display: flex;
  align-items: center;
}

.FormTextInput {
  padding: 8px 12px;
  border-radius: 6px;
  border: none;
}

button.FormButton {
  padding: 8px;
  border-radius: 10px;
  font-weight: 600;
  border: none;
  background-color: white;
  box-sizing: border-box;
}

p {
  padding: 0px;
  margin: 0px;
  text-align: left;
  font-family: inherit;
}

button {
  background-color: transparent;
  border: none;
  font-family: inherit !important;
}